:root {

  --primary-red: #c42026;
}


.MuiFormLabel-root {
  /* color: #c42026!important; */
  color: var(--primary-red) !important
}


.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: yellow !important;
}

.next-color {
  background-color: #c42026;
}

.pre-color {
  background-color: #f8f9fa;
  border-color: beige;
}

.font-color-theme {
  color: #c42026;

}

.font-color-grey {
  color: rgb(201, 196, 196);
}

.text-height {
  line-height: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

.bord_right {
  border-right: 0px !important;

}

.cardStyle {
  height: 100px !important;
}



.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 200px;
}

.btn-circle.btn-xl {
  width: 30px;
  height: 30px;
  padding: 18px 18px;
  border-radius: 60px;
  font-size: 15px;
}

.txt {
  margin-bottom: 200px;
}

.cam-height {
  height: 100px;
}

.btn-color {
  background-color: #c42026;
}

.dis label {
  background-color: rgb(255, 255, 255) !important;
}

.nic_uploadBtn {
  width: 100%;
  height: 200px;
  color: #c42026 !important;
  border: 2px dashed #c42026 !important;
  flex-direction: column;
}

.preview {
  position: relative;
}


.nicPreview img {
  width: 100%;
  height: 200px !important;
  object-fit: contain;
  background-color: #fff !important;
  border: 2px dashed #c42026 !important;
}




/* When the browser is at least 600px and above */
@media screen and (max-width: 1200px) {
  .cam-height {
    height: 80px;
  }

  .text-mob {
    font-size: 5px;
  }

}

@media screen and (max-width: 1200px) {
  .cam-height {
    height: 80px;
  }

  .text-mob {
    font-size: 5px;
  }


}


/* 
@media(min-width: 768px) and (max-width: 926px) {
  .cam-height {
      height: 55px;
     }

     .cardStyle {
      padding: 0 !important;
     }

     .cardStyle .MuiPaper-root {
      margin: 3px !important;
     }
    
} */



@media(max-width: 364px) {
  .cam-height {
    height: 28px;
  }

  .mob-font {
    font-size: 8px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;

  }
}


.active {
  background-color: #c42026 !important;
  color: white !important;
}

.colll {
  fill: pink !important;
}


/* .MuiPaper-root{
  width: 70px!important;
  margin-bottom: -40px!important;
  margin-left: 0px!important;
  ;
}

.MuiGrid-root {
  width: 100px, !important;;
}
 */

.alert {
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  margin-bottom: 10px;
}

.MuiList-root {
  height: 120px !important;
}

.thank-color {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  color: #c42026;
}

.bg-cl .MuiOutlinedInput-notchedOutline {
  border-color: #c42026 !important;
}

.bordered-table {
  border-collapse: collapse;
  /* Collapse the border spacing */
  width: 100%;
  /* Optional: Set the table width to 100% */
}

.bordered-table th,
.bordered-table td {
  border: 1px solid black;
  /* Add a 1px solid black border to table headers and data cells */
  text-align: center;
  /* Optional: Center-align text within cells */
}

.headFont {
  font-size: 12px;
  font-weight: 900;
}

.dataFont {
  font-size: 12px;
}

.borderLeft {
  border-left: none !important;
}

.borderRight {
  border-right: none !important;
}

.borderBottom {
  border-bottom: none !important;
}

.borderTop {
  border-top: none !important;
}

.sticky-button {
  display: none;
}

.cardSize {
  margin-left: 30px !important;
  width: 11%;
  margin-top: -40px !important;
  margin: 5px;
  margin-top: 20px !important;
  /* background-color: yellow !important; */
  text-align: center;
  align-items: center;
  align-content: center;

}

@media (min-width: 1000px) and (max-width: 1140px) {
  .cardSize {
    margin-left: 35px !important;
    width: 10%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    /* background-color: yellow !important; */
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 940px) and (max-width: 1000px) {
  .cardSize {
    margin-left: 30px !important;
    width: 10%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    /* background-color: yellow !important; */
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 910px) and (max-width: 940px) {
  .cardSize {
    margin-left: 18px !important;
    width: 8%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    /* background-color: yellow !important; */
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 900px) and (max-width: 910px) {
  .cardSize {
    margin-left: 17px !important;
    width: 8%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    /* background-color: yellow !important; */
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

.imageSize {
  height: 30px !important;
}

.mob-font {
  font-size: 11px !important;
  margin-top: -10px !important;
  /* padding-left: 5px !important;
  padding-right: 5px !important; */

}


@media (min-width: 600px) {
  .mob-font-small {
    display: none;
  }
}

@media (max-width: 590px) {
  .mob-font {
    display: none;
  }
}

@media(min-width: 901px) {
  .cardDiv {
    display: none;
  }
}

@media(max-width: 940px) {
  .cardSize {
    width: 14%;
  }

  .mob-font {
    font-size: 11px !important;
    margin-top: -10px !important;
    /* padding-left: 5px !important;
    padding-right: 5px !important; */

  }

  .cardDiv {
    align-items: center !important;
    align-content: center !important;
  }

  .cardFont {
    font-size: 12px;
    height: 40px;
    padding: 0px !important;
    text-align: center;
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.6)
  }

  .imgSize {
    height: 30px;
  }

  .cardSize {
    width: 10%;
    margin-top: -40px !important;
    margin: 18px;
    margin-top: 20px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 770px) and (max-width: 899px) {

  .mob-font {
    font-size: 11px;
  }

  .cardSize {
    margin-left: 25px !important;
    width: 10%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 700px) and (max-width: 769px) {

  .mob-font {
    font-size: 11px;
  }

  .cardSize {
    margin-left: 15px !important;
    width: 11%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;

  }
}


@media(max-width: 810px) {
  .cam-height {
    height: 45px;
  }

  .cardStyle {
    padding: 0 !important;
  }

  .cardStyle .MuiPaper-root {
    margin: 3px !important;
  }

  .mob-font {
    font-size: 9px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;

  }

  .imageSize {
    height: 25px !important;
  }
}

@media (min-width: 600px) and (max-width: 699px) {

  .mob-font {
    font-size: 11px;
  }

  .cardSize {
    margin-left: 12px !important;
    width: 11%;
    margin-top: -40px !important;
    margin: 5px;
    margin-top: 20px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;

  }
}

@media (min-width: 550px) and (max-width: 599px) {

  .cardSize {
    margin-left: 20px !important;
    width: 10%;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 0px;

  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }



}

@media (min-width: 465px) and (max-width: 549px) {

  .cardSize {
    margin-left: 2%;
    width: 15%;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 10px;

  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }



}

@media (min-width: 430px) and (max-width: 464px) {


  .cardSize {
    width: 15%;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 6px;

  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 429px) {


  .cardSize {
    width: 14.8%;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 0px;

  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 400px) {


  .cardSize {
    width: 14.2%;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 4px;

  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 380px) {

  .cardSize {
    width: 55px;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin-left: 12px !important;


  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 344px) {

  .cardSize {
    width: 50px;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin-left: 8px !important;


  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 344px) {

  .cardSize {
    width: 60px;
    margin-top: 5px !important;
    background-color: yellow !;
    text-align: center;
    align-items: center;
    align-content: center;
    margin-left: 2px !important;


  }

  .imageSize {
    height: 15px !important;
  }

  .fontSize {
    font-size: 20px !important;
  }

  .mob-font {
    display: none !important;
    margin-top: -10px !important;
  }

  .mob-font-small {
    font-size: 8px !important;
    margin-top: -10px !important;

  }

  .mobSize {
    margin-top: -25px;
  }
}

@media(max-width: 900px) {
  .sticky-button {
    position: fixed;
    bottom: 20px;
    right: 10px;
    background-color: #c42026;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
  }

  /* Additional styling to make it look good */
  body {
    margin: 0;
    padding: 0;
  }

  #targetDiv {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 24px;
  }
}