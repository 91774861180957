
.mainSection {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Ac-section
{
    background-color:#c4161c;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    border-top-right-radius: 19%;
    border-top-left-radius:19%;
    background-image:url() ;
    height: 70vh;
    /* background: url(./assets/back.png) no-repeat; */
    
  }

  .tile
{
  width:80;
  margin:60px auto;
}
#tile-1 .tab-pane
{
  padding:15px;
  height:80px;
}
#tile-1 .nav-tabs{
  position:relative;
  border:none!important;
  /* background-color:#fff; */
/*   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); */
  border-radius:6px;
}
#tile-1 .nav-tabs li{
  margin:0px!important;
}
#tile-1 .nav-tabs li a{
  position:relative;
  margin-right:0px!important;
  padding: 20px 40px!important;
  font-size:16px;
  border:none!important;
  color:white;
}
#tile-1 .nav-tabs a:hover{
  background-color:#fff!important;
  border: none!important;;
}
#tile-1 .slider{
  display:inline-block;
  width:380px;
  height:4px;
  border-radius:3px;
  background-color:#ffffff; 
  position:absolute;
  z-index:1200;
  bottom:0;
  transition:all .4s linear;
  margin-top: 20px;
  
} 

#tile-1 .nav-tabs .active{
  background-color:transparent!important;
  /* border:none!important; */
  color:white!important;
}
/* 
.bord{
  background-color: yellow;
}
.bord li{
  border: 2px solid pink!important;
} */

.bord li:focus-visible {
  border: 6px dashed crimson;
  border-radius: 3px;
  outline: none;
}
.bord li:focus {
  border: none!important;
}
.bord li:hover {
  border: none!important;
}



.bd{

  color: yellow!important;
}

.button-style{
  background: none;
  border: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.tooltip-trigger {
  border: none;
  background-color: transparent;
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
}

.visible {
  visibility: visible;
  opacity: 1;
}
/* .dd:hover {
  border: 1px solid white !important;
} */




@media(max-width: 768px) {
  .bord{
    flex-direction: column;
   }
   
  
}



@media (min-width: 768px) and (max-width: 820px) {
  .la{
    font-size: 30px !important;
   }
   
}
/* @media(max-width: 768px) {
  .fa{
    font-size: 30px !important;
   }
   
  
}

@media(min-width: 768px) {
  .fa{
    font-size: 30px !important;
   }
   
  
} */







.selected {
  border: 1px solid white !important;/* You can customize the highlight style */
}




@media(max-width: 768px) {
  #tile-1 .slider{
    display: none;
    
   }
   
   
  
}